import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import throttle from 'lodash.throttle';
import Logo from 'assets/svg/logo.svg';
import {
  HeaderContainer, OuterWrapper, StyledBurger,
  StyledLogo, StyledNavigation, Wrapper,
} from './Navigation.styles';
import { SubMenuListItemWrapper } from './SubMenuListItemWrapper';

export const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const toggleNavigation = () => {
    setIsOpen(!isOpen);
  };

  const closeNavigation = () => {
    setIsOpen(false);
  };

  const handleScroll = throttle(() => {
    if (window.scrollY > 20 && !isScrolled) {
      setIsScrolled(true);
    }
    if (window.scrollY === 0 && isScrolled) {
      setIsScrolled(false);
    }
  }, 100);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isScrolled]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isOpen]);

  return (
    <header>
      <OuterWrapper>
        <HeaderContainer isScrolled={isScrolled}>
          <StyledLogo to="/" isSmall isMobile aria-label="Strona główna">
            <Logo />
          </StyledLogo>
          <StyledBurger onClick={toggleNavigation} isOpen={isOpen} aria-controls="menu">
            <div></div>
          </StyledBurger>
          <Wrapper isOpen={isOpen}>
            <StyledLogo to="/" aria-label="Strona główna">
              <Logo />
            </StyledLogo>
            <StyledNavigation role="navigation" aria-label="Main menu">
              <ul id="menu">
                <li>
                  <Link to="/o-nas" onClick={closeNavigation}>O nas</Link>
                </li>
                <li>
                  <Link to="/aktualnosci" onClick={closeNavigation}>Aktualności</Link>
                </li>
                <SubMenuListItemWrapper title="Dla pacjentów">
                  <li>
                    <Link to="/rejestracja" onClick={closeNavigation}>Rejestracja</Link>
                  </li>
                  <li>
                    <Link to="/programy-profilaktyczne" onClick={closeNavigation}>Programy profilaktyczne</Link>
                  </li>
                  <li>
                    <Link to="/wizyty-domowe" onClick={closeNavigation}>Wizyty domowe</Link>
                  </li>
                  <li>
                    <Link to="/druki-i-formularze" onClick={closeNavigation}>Druki i formularze</Link>
                  </li>
                  <li>
                    <Link to="/transport-medyczny" onClick={closeNavigation}>Transport medyczny</Link>
                  </li>
                  <li>
                    <Link to="/nocna-i-swiateczna-pomoc-medyczna" onClick={closeNavigation}>Nocna i świąteczna pomoc medyczna</Link>
                  </li>
                </SubMenuListItemWrapper>
                <SubMenuListItemWrapper title="Poradnie i jednostki">
                  <li>
                    <Link to="/poradnie-i-jednostki/poradnia-lekarza-rodzinnego" onClick={closeNavigation}>Poradnia lekarza rodzinnego</Link>
                  </li>
                  <li>
                    <Link to="/poradnie-i-jednostki/poradnia-dziecieca" onClick={closeNavigation}>Poradnia dziecięca</Link>
                  </li>
                  <li>
                    <Link to="/poradnie-i-jednostki/poradnie-specjalistyczne" onClick={closeNavigation}>Poradnie specjalistyczne</Link>
                  </li>
                  <li>
                    <Link to="/poradnie-i-jednostki/poradnia-ginekologiczno-poloznicza" onClick={closeNavigation}>Poradnia ginekologiczno-położnicza</Link>
                  </li>
                  <li>
                    <Link to="/poradnie-i-jednostki/gabinet-zabiegowy" onClick={closeNavigation}>Gabinet zabiegowy</Link>
                  </li>
                  <li>
                    <Link to="/stomatologia" onClick={closeNavigation}>Stomatologia</Link>
                  </li>
                  <li>
                    <Link to="/poradnie-i-jednostki/diagnostyka" onClick={closeNavigation}>Diagnostyka</Link>
                  </li>
                  <li>
                    <Link to="/poradnie-i-jednostki/poradnie-specjalistyczne-na-nfz-na-terenie-bydgoszczy" onClick={closeNavigation}>Poradnie specjalistyczne na NFZ na terenie Bydgoszczy</Link>
                  </li>
                </SubMenuListItemWrapper>
                <li>
                  <Link to="/cennik" onClick={closeNavigation}>Cennik</Link>
                </li>
                <li>
                  <Link to="/kontakt" onClick={closeNavigation}>Kontakt</Link>
                </li>
              </ul>
            </StyledNavigation>
          </Wrapper>
        </HeaderContainer>
      </OuterWrapper>
    </header>
  );
};
