exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktualnosci-jsx": () => import("./../../../src/pages/aktualnosci.jsx" /* webpackChunkName: "component---src-pages-aktualnosci-jsx" */),
  "component---src-pages-cennik-jsx": () => import("./../../../src/pages/cennik.jsx" /* webpackChunkName: "component---src-pages-cennik-jsx" */),
  "component---src-pages-druki-i-formularze-jsx": () => import("./../../../src/pages/druki-i-formularze.jsx" /* webpackChunkName: "component---src-pages-druki-i-formularze-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-nocna-i-swiateczna-pomoc-medyczna-jsx": () => import("./../../../src/pages/nocna-i-swiateczna-pomoc-medyczna.jsx" /* webpackChunkName: "component---src-pages-nocna-i-swiateczna-pomoc-medyczna-jsx" */),
  "component---src-pages-o-nas-jsx": () => import("./../../../src/pages/o-nas.jsx" /* webpackChunkName: "component---src-pages-o-nas-jsx" */),
  "component---src-pages-polityka-prywatnosci-jsx": () => import("./../../../src/pages/polityka-prywatnosci.jsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-jsx" */),
  "component---src-pages-poradnie-i-jednostki-diagnostyka-jsx": () => import("./../../../src/pages/poradnie-i-jednostki/diagnostyka.jsx" /* webpackChunkName: "component---src-pages-poradnie-i-jednostki-diagnostyka-jsx" */),
  "component---src-pages-poradnie-i-jednostki-gabinet-zabiegowy-jsx": () => import("./../../../src/pages/poradnie-i-jednostki/gabinet-zabiegowy.jsx" /* webpackChunkName: "component---src-pages-poradnie-i-jednostki-gabinet-zabiegowy-jsx" */),
  "component---src-pages-poradnie-i-jednostki-poradnia-dziecieca-jsx": () => import("./../../../src/pages/poradnie-i-jednostki/poradnia-dziecieca.jsx" /* webpackChunkName: "component---src-pages-poradnie-i-jednostki-poradnia-dziecieca-jsx" */),
  "component---src-pages-poradnie-i-jednostki-poradnia-ginekologiczno-poloznicza-jsx": () => import("./../../../src/pages/poradnie-i-jednostki/poradnia-ginekologiczno-poloznicza.jsx" /* webpackChunkName: "component---src-pages-poradnie-i-jednostki-poradnia-ginekologiczno-poloznicza-jsx" */),
  "component---src-pages-poradnie-i-jednostki-poradnia-lekarza-rodzinnego-jsx": () => import("./../../../src/pages/poradnie-i-jednostki/poradnia-lekarza-rodzinnego.jsx" /* webpackChunkName: "component---src-pages-poradnie-i-jednostki-poradnia-lekarza-rodzinnego-jsx" */),
  "component---src-pages-poradnie-i-jednostki-poradnie-specjalistyczne-jsx": () => import("./../../../src/pages/poradnie-i-jednostki/poradnie-specjalistyczne.jsx" /* webpackChunkName: "component---src-pages-poradnie-i-jednostki-poradnie-specjalistyczne-jsx" */),
  "component---src-pages-poradnie-i-jednostki-poradnie-specjalistyczne-na-nfz-na-terenie-bydgoszczy-jsx": () => import("./../../../src/pages/poradnie-i-jednostki/poradnie-specjalistyczne-na-nfz-na-terenie-bydgoszczy.jsx" /* webpackChunkName: "component---src-pages-poradnie-i-jednostki-poradnie-specjalistyczne-na-nfz-na-terenie-bydgoszczy-jsx" */),
  "component---src-pages-programy-profilaktyczne-jsx": () => import("./../../../src/pages/programy-profilaktyczne.jsx" /* webpackChunkName: "component---src-pages-programy-profilaktyczne-jsx" */),
  "component---src-pages-recepta-jsx": () => import("./../../../src/pages/recepta.jsx" /* webpackChunkName: "component---src-pages-recepta-jsx" */),
  "component---src-pages-rehabilitacja-jsx": () => import("./../../../src/pages/rehabilitacja.jsx" /* webpackChunkName: "component---src-pages-rehabilitacja-jsx" */),
  "component---src-pages-rejestracja-jsx": () => import("./../../../src/pages/rejestracja.jsx" /* webpackChunkName: "component---src-pages-rejestracja-jsx" */),
  "component---src-pages-rodo-jsx": () => import("./../../../src/pages/rodo.jsx" /* webpackChunkName: "component---src-pages-rodo-jsx" */),
  "component---src-pages-stomatologia-jsx": () => import("./../../../src/pages/stomatologia.jsx" /* webpackChunkName: "component---src-pages-stomatologia-jsx" */),
  "component---src-pages-transport-medyczny-jsx": () => import("./../../../src/pages/transport-medyczny.jsx" /* webpackChunkName: "component---src-pages-transport-medyczny-jsx" */),
  "component---src-pages-wizyty-domowe-jsx": () => import("./../../../src/pages/wizyty-domowe.jsx" /* webpackChunkName: "component---src-pages-wizyty-domowe-jsx" */),
  "component---src-templates-news-template-jsx": () => import("./../../../src/templates/NewsTemplate.jsx" /* webpackChunkName: "component---src-templates-news-template-jsx" */),
  "component---src-templates-programy-profilaktyczne-template-jsx": () => import("./../../../src/templates/ProgramyProfilaktyczneTemplate.jsx" /* webpackChunkName: "component---src-templates-programy-profilaktyczne-template-jsx" */)
}

