import styled from 'styled-components';

export const SubMenuListItem = styled.li`
  position: relative;
  font-family: ${({ theme }) => theme.font.family.catamaran};
  color: ${({ theme }) => theme.color.darkGray};
  font-weight: 700;
  text-decoration: none;
  font-size: 2rem;
  padding-bottom: 0 !important;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  &:hover, &:focus{
    color: ${({ theme }) => theme.color.green};
  }

  div {
    padding-bottom: 15px;

    ${({ theme }) => theme.mq.desktop} {
      padding-bottom: 0;
    }
  }

  ul {
    max-height: ${({ height }) => height}px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    li {
      padding: 15px 0;
      margin: 0;
      background-color: ${({ theme }) => theme.color.lightGray};

      &:not(:last-child){
        border-bottom: 1px solid ${({ theme }) => theme.color.green};
      }
    }

    ${({ theme }) => theme.mq.desktop} {
      position: absolute;
      max-height: unset;
      opacity: 0;
      visibility: hidden;
      flex-direction: column;
      left: 50%;
      transform: translate(-50%);
      top: calc(100% + 10px);
      box-shadow: 0 2px 10px rgb(0, 0, 0, 15%);
      border-radius: 5px;
      background-color: ${({ theme }) => theme.color.white};
      transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

      li {
        width: 100%;
        padding: 15px 20px;
        background-color: ${({ theme }) => theme.color.white};
        margin-left: 0;
        white-space: nowrap;

        &:hover{
          background-color: ${({ theme }) => theme.color.lightGray};

          a {
            color: ${({ theme }) => theme.color.green};
          }
        }

        &:not(:last-child){
        border-bottom: none;
      }
      }
    }
  }

  ${({ theme }) => theme.mq.desktop} {
    &:hover, &:focus{
      ul{
        opacity: 1;
        visibility: visible;
      }
    }
  }
`;
