import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { Container } from '../Container/Container.styles';

export const StyledLogo = styled(({ isMobile, isSmall, ...props }) => <Link {...props} />)`
  width: ${({ isSmall }) => (isSmall ? '80px' : '100px')};
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${({ theme }) => theme.mq.desktop} {
    transition: transform .3s ease-in-out;
    transform-origin: left;
    display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
    width: ${({ isSmall }) => (isSmall ? '80px' : '100px')};
  }
`;

export const HeaderContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: ${({ isScrolled }) => (isScrolled ? '80px' : '94px')};
  transition: height .3s ease-in-out;
  will-change: height;

  ${({ theme }) => theme.mq.desktop} {
    height: ${({ isScrolled }) => (isScrolled ? '100px' : '115px')};
    padding: 0;

    ${StyledLogo} {
      transform: ${({ isScrolled }) => (isScrolled ? 'scale(0.9)' : 'scale(1)')};
    }
  }
`;

export const OuterWrapper = styled.div`
  box-shadow: 0 2px 10px rgb(0, 0, 0, 15%);
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: ${({ theme }) => theme.color.white};
`;

export const StyledBurger = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: flex-start;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 1050;

  div{
    width: 30px;
    height: 3px;
    background-color: ${({ isOpen }) => (isOpen ? 'transparent' : '#255349')};
    transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
    transform: ${({ isOpen }) => (isOpen ? 'translateX(-50px)' : 'translateX(0)')};
    margin-bottom: 5px;

    ::after,
    ::before {
      content: "";
      position: absolute;
      width: 30px;
      height: 3px;
      background-color: ${({ theme }) => theme.color.darkGreen};
      transition: transform 0.3s ease-in-out;
    }

    ::after {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg) translate(35px, 35px)' : 'translateY(9px)')};
    }

    ::before {
      transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg) translate(35px, -35px)' : 'translateY(-9px)')};
    }
  }
  
  ${({ theme }) => theme.mq.desktop} {
    display: none;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  background-color: white;
  align-items: center;
  justify-content: flex-start;
  padding: 50px 0;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(0)' : 'translateY(-102%)')};
  opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
  
  ${({ theme }) => theme.mq.desktop} {
    position: static;
    display: flex;
    overflow: visible;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: transparent;
    padding: 0;
    transform: unset;
    opacity: unset;
  }

  &::-webkit-scrollbar {
      width: 5px;
  }

  &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.color.green};
      border-radius: 10px;
  }

  div:last-child{
    margin-top: auto;
    padding-top: 20px;

    ${({ theme }) => theme.mq.desktop} {
      padding-top: 0;
      margin-top: unset;
    }
  }
`;

export const StyledNavigation = styled.nav`
  width: 100%;
  margin-top: 20px;

  ul {
    list-style: none;
    padding: 0;
    text-align: center;
    li {
      padding: 15px 0;
      a, span {
        font-family: ${({ theme }) => theme.font.family.catamaran};
        color: ${({ theme }) => theme.color.darkGray};
        font-weight: 700;
        text-decoration: none;
        font-size: 2rem;
        transition: color 0.3s ease-in-out;

        &:hover{
          color: ${({ theme }) => theme.color.green};
        }
      }
    }
  }
  
  ${({ theme }) => theme.mq.desktop} {
    width: unset;
    margin-top: 0;
    
    ul {
      display: flex;
      align-items: center;
      
      li {
        margin-left: 30px;
        padding: 0;
      }
    }
  }

  ${({ theme }) => theme.mq.bigDesktop} {
    ul li {
      margin-left: 40px;
    } 
  }
`;

export const Button = styled.div`
  a{
    font-family: ${({ theme }) => theme.font.family.fira};
    font-weight: 500;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.darkGold};
    border: 2px solid ${({ theme }) => theme.color.darkGold};
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

    &:hover{
      background-color: ${({ theme }) => theme.color.darkGold};
      color: ${({ theme }) => theme.color.white};
    }
  }
`;
