import styled from 'styled-components';

export const StyledFooter = styled.footer`
  padding-top: 10px;
  background-color: ${({ theme }) => theme.color.green};
`;

export const FooterWrapper = styled.div`
  margin: 30px 0;
  text-align: center;

  ${({ theme }) => theme.mq.tablet} {
    display: flex;
    justify-content: space-between;
    text-align: left;
    gap: 15px;
    margin-bottom: 50px;
  }
`;

export const FooterContent = styled.div`
  h3 {
    font-weight: 700;
    font-size: 2rem;
    color: ${({ theme }) => theme.color.white};
  }

  p {
    font-weight: 400;
    margin: 5px 0;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color.white};

    ${({ theme }) => theme.mq.desktop} {
      font-size: 2rem;
    }
  }

  a {
    font-size: 1.6rem;
    display: block;
    text-decoration: none;
    font-weight: 700;
    color: ${({ theme }) => theme.color.white};
    transition: color .3s ease-in-out;

    ${({ theme }) => theme.mq.desktop} {
      font-size: 2rem;
    }

    &:hover {
      color: ${({ theme }) => theme.color.lightGreen};
    }
  }
`;

export const FooterCopyright = styled.div`
  background-color: ${({ theme }) => theme.color.darkGreen};
  padding: 5px 0;
  text-align: center;

  p {
    font-size: 1.2rem;
    font-weight: 600;
    color: ${({ theme }) => theme.color.white};
  }
`;
