import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin: auto;
  padding: 0 15px;
  position: ${({ isRelative }) => (isRelative ? 'relative' : 'unset')};

  ${({ theme }) => theme.mq.mobile} {
    max-width: ${({ theme }) => theme.containerWidth.mobile};
    padding: 0;
  }

  ${({ theme }) => theme.mq.tablet} {
    max-width: ${({ theme }) => theme.containerWidth.tablet};
  }

  ${({ theme }) => theme.mq.desktop} {
    max-width: ${({ theme }) => theme.containerWidth.desktop};
  }

  ${({ theme }) => theme.mq.bigDesktop} {
    max-width: ${({ theme }) => theme.containerWidth.bigDesktop};
  }
`;
