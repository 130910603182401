import React, { useEffect, useState } from 'react';
import { Container } from '../Container/Container.styles';
import { CookiesContainer } from './Cookies.styles';

export const Cookies = () => {
  const [isVisible, setisVisible] = useState(false);
  const [isTransitioned, setisTransitioned] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('cookiesAccepted') === null) {
      setTimeout(() => {
        setisVisible(true);
      }, 5000);
      setTimeout(() => {
        setisTransitioned(true);
      }, 6000);
    }
  }, []);

  const closeCookies = () => {
    setisVisible(false);
    localStorage.setItem('cookiesAccepted', true);
  };

  return (
    <CookiesContainer isVisible={isVisible} isTransitioned={isTransitioned}>
      <Container>
        <p>
          Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie.
          Dalsze korzystanie ze strony oznacza, że zgadzasz sie na ich użycie
        </p>
        <button onClick={closeCookies}>Akceptuj</button>
      </Container>
    </CookiesContainer>
  );
};
