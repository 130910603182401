export const theme = {
  color: {
    white: '#FFFFFF',
    lightGray: '#F5F5F5',
    lightGrayBg: '#F7F9FA',
    black: '#000000',
    darkGray: '#1A090D',
    green: '#30695d',
    lightGreen: '#59B4A9',
    veryLightGreen: '#cee9e6',
    darkGreen: '#255349',
    red: '#E3170A',
    success: '#198754',
    error: '#ff3333',
    blackOverlay: 'rgba(0, 0, 0, 0.5)',
    greenOverlay: 'rgba(89, 180, 169, 0.68)',
  },
  size: {
    desktopHeroHeight: '800px',
  },
  font: {
    shadow: '5px 5px 10px hsla(0, 0%, 0%, 0.15)',
    size: {
      heading: '5rem',
      headingMobile: '5.3rem',
      headingSmall: '4.2rem',
      paragraph: '1.5rem',
      caption: '1.35rem',
      mobileMenu: '2.1rem',
      button: '1.2rem',
      thumbnail: '1.8rem',
      thumbnailSmall: '1.4rem',
    },
    family: {
      fira: '"Fira Sans", sans-serif',
      cormorant: '"Cormorant Garamond", serif',
      catamaran: '"Catamaran", sans-serif',
      merriweather: '"Merriweather Sans", sans-serif',
    },
  },
  mq: {
    smallMobile: '@media (min-width: 360px)',
    mobile: '@media (min-width: 540px)',
    tablet: '@media (min-width: 768px)',
    desktop: '@media (min-width: 1024px)',
    bigDesktop: '@media (min-width: 1360px)',
    huge: '@media(min-width: 1440px)',
  },
  containerWidth: {
    mobile: '520px',
    tablet: '748px',
    desktop: '980px',
    bigDesktop: '1320px',
    huge: '1400px',
  },
};
